<template>
  <div class="login-container">
    <Card title="Login User" hide-collapse width="90%" max-width="550px">
      <v-text-field
        v-model="email"
        type="email"
        label="Email"
        :rules="[rules.required]"
        @keypress="handleKeyPress"
      ></v-text-field>

      <v-text-field
        v-model="password"
        :rules="[rules.required]"
        name="input-10-1"
        label="Enter Password"
        :type="showPassword ? 'text' : 'password'"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPassword = !showPassword"
        @keypress="handleKeyPress"
      ></v-text-field>

      <p class="mb-3">
        <router-link to="/forgot-password">Forgot Password</router-link>
      </p>

      <p class="text-center">
        <v-btn
          @click="login"
          color="secondary"
          :disabled="loading"
          :loading="loading"
          >Login</v-btn
        >
      </p>
    </Card>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      showPassword: false,
      loading: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  methods: {
    ...mapMutations(["SET_USER"]),

    async login() {
      try {
        this.loading = true;
        if (!this.email || !this.password) return;

        const resp = await this.axios.post("/login", {
          email: this.email,
          password: this.password,
        });

        const user = resp.data.user;
        user.role = Number(user.role);
        this.SET_USER(user);
        this.$router.push("/");
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    handleKeyPress(event) {
      if (event.key === "Enter") {
        this.login();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: grid;
  place-items: center;

  background-image: url("../../assets/login_background.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
